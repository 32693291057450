import { lotame } from '@tunein/web-common';
import { mintSingleton } from '../../client/mint';
import { tunerSingleton } from '../../client/tuner';
import web from '../constants/analytics/categoryActionLabel/web';
import { IHEARTRADIO_ALBUM_ART_ENABLED } from '../constants/experiments/player';
import { doNotFetch } from '../constants/locationState';
import { playerStatuses } from '../constants/playerStatuses';
import { selectDiscordState, selectIsMobile } from '../selectors/app';
import { selectExperiment } from '../selectors/config';
import {
  selectBoostGuideId,
  selectNowPlaying,
  selectPlaybackRate,
  selectPlayerStatus,
  selectTunedGuideId,
} from '../selectors/player';
import { getSelectProfile } from '../selectors/profiles';
import { buildGuideItemPathnameWithLogger } from '../utils/guideItem/getGuideItemPathname';
import {
  isPodcastProfile,
  isProfile,
  isProgram,
} from '../utils/guideItemTypes';
import { determineInAppMessageDialogRequestBehavior } from './dialog';
import {
  logCategoryActionLabel,
  logClientError,
  logPlayStartNext,
  logPlayStartQualified,
  logStreamEvaluationFailTime,
  logStreamEvaluationSuccessTime,
  logTotalTuneFailTime,
  logTotalTuneSuccessTime,
} from './logging';
import { setPlaybackHistory } from './playbackHistory';
import { fetchProfile } from './profile';

export const PLAYING = 'PLAYING';
export const CONNECTING = 'CONNECTING';
export const LOADING = 'LOADING';
export const WAITING = 'WAITING';
export const NOW_PLAYING_CHANGED = 'NOW_PLAYING_CHANGED';
export const POSITION_CHANGED = 'POSITION_CHANGED';
export const MEDIA_ENDED = 'MEDIA_ENDED';
export const FAILED = 'FAILED';
export const QUALIFIED_TUNE = 'QUALIFIED_TUNE';
export const HANDLE_HTML_STREAM = 'HANDLE_HTML_STREAM';
export const NEXT_TUNE = 'NEXT_TUNE';
export const START_STREAM_EVALUATION = 'START_STREAM_EVALUATION';
export const CHANGE_PLAYBACK_RATE = 'CHANGE_PLAYBACK_RATE';
export const HANDLE_META = 'HANDLE_META';
export const PLAYER_SELECTED = 'PLAYER_SELECTED';
export const CLEAR_PLAYER_NAME_LIST = 'CLEAR_PLAYER_NAME_LIST';
export const SET_LISTEN_ID = 'SET_LISTEN_ID';
export const INITIATE_INTRO = 'INITIATE_INTRO';
export const INITIATE_OUTRO = 'INITIATE_OUTRO';
export const TUNER_READY = 'TUNER_READY';

export function changePlaybackRateEffect(rate, canChangePlaybackRate) {
  return {
    type: CHANGE_PLAYBACK_RATE,
    rate,
    canChangePlaybackRate,
  };
}

export function changePlaybackRateIfPossible(rate, isUserInitiatedSeek) {
  return (dispatch, getState) => {
    const defaultRate = 1.0;
    const state = getState();
    const { primaryGuideId } = selectNowPlaying(state);
    const playerStatus = selectPlayerStatus(state);
    const discordState = selectDiscordState(state);
    const profile = getSelectProfile(primaryGuideId)(state);
    const playbackRate = selectPlaybackRate(state);
    const isPodcast = isPodcastProfile(profile) || isProgram(primaryGuideId);
    const rateToUse = Number.parseFloat(rate || playbackRate || defaultRate);
    const canChangePlaybackRate =
      tunerSingleton.instance?.canChangePlaybackRate();

    if (!discordState.canControlPlayback && isUserInitiatedSeek) {
      return;
    }

    if (!canChangePlaybackRate) {
      return dispatch(changePlaybackRateEffect(defaultRate, false));
    }

    if (!isPodcast) {
      tunerSingleton.instance?.changePlaybackRate(defaultRate);
      return dispatch(changePlaybackRateEffect(defaultRate, false));
    }

    if (
      playerStatus === playerStatuses.playing ||
      playerStatus === playerStatuses.connecting
    ) {
      tunerSingleton.instance?.changePlaybackRate(rateToUse);
    }

    // we only want to log if a rate has been specified by user selection
    if (rate) {
      dispatch(
        logCategoryActionLabel({
          category: web.category,
          action: `${rateToUse}`,
          label: web.labels.changePlaybackRate,
        }),
      );
    }

    return dispatch(changePlaybackRateEffect(rateToUse, true));
  };
}

export function playing(streamData = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      tunedGuideId,
      boostGuideId,
      streamEvaluationStart,
      playerNameList,
      itemToken,
      tuneRequestStart,
      hadPreroll,
    } = state.player;
    const guideId = boostGuideId || tunedGuideId;
    const now = Date.now();

    if (streamEvaluationStart && !streamData.isBoostTransition) {
      const time = now - streamEvaluationStart;

      dispatch(
        logStreamEvaluationSuccessTime({
          guideId,
          time,
          playerNameList,
          itemToken,
          hadPreroll,
        }),
      );
    }

    if (tuneRequestStart && !streamData.isBoostTransition) {
      const time = now - tuneRequestStart;

      dispatch(
        logTotalTuneSuccessTime({
          guideId,
          time,
          playerNameList,
          itemToken,
          hadPreroll,
        }),
      );
    }

    return dispatch({
      type: PLAYING,
      currentStream: streamData,
    }).then(() => dispatch(changePlaybackRateIfPossible()));
  };
}

export function connecting(nextGuideId) {
  return {
    type: CONNECTING,
    tunedGuideId: nextGuideId,
  };
}

export function loading() {
  return {
    type: LOADING,
  };
}

export function waiting() {
  return {
    type: WAITING,
  };
}

export function nowPlayingChanged(nowPlaying = {}) {
  return async (dispatch, getState) => {
    mintSingleton.updateState('nowPlaying', nowPlaying);
    const { primaryGuideId } = nowPlaying;
    const prevPrimaryGuideId = getState().player?.nowPlaying?.primaryGuideId;
    const inAppMessageKey = nowPlaying.popup?.destinationInfo?.id;

    if (prevPrimaryGuideId !== primaryGuideId) {
      lotame.addInterests(nowPlaying);
    }

    if (inAppMessageKey && prevPrimaryGuideId !== primaryGuideId) {
      dispatch(
        determineInAppMessageDialogRequestBehavior(
          inAppMessageKey,
          primaryGuideId,
        ),
      );
    }

    return dispatch({ type: NOW_PLAYING_CHANGED, nowPlaying });
  };
}

export function positionChanged(positionInfo) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(
      setPlaybackHistory(
        selectTunedGuideId(state),
        positionInfo.elapsedPercent,
      ),
    );
    return dispatch({
      type: POSITION_CHANGED,
      positionInfo,
      isMobile: selectIsMobile(getState()),
    });
  };
}

export function playerSelected(playerName) {
  return {
    type: PLAYER_SELECTED,
    playerName,
  };
}

export function clearPlayerNameList() {
  return {
    type: CLEAR_PLAYER_NAME_LIST,
  };
}

export function mediaEnded() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(
      setPlaybackHistory(
        selectTunedGuideId(state),
        state.player.positionInfo.elapsedPercent,
      ),
    );
    dispatch({
      type: MEDIA_ENDED,
    });
  };
}

export function streamFailed() {
  return (dispatch, getState) => {
    const {
      tunedGuideId: guideId,
      streamEvaluationStart,
      playerNameList,
      itemToken,
      hadPreroll,
    } = getState().player;

    const now = Date.now();

    if (streamEvaluationStart) {
      const time = now - streamEvaluationStart;

      dispatch(
        logStreamEvaluationFailTime({
          guideId,
          time,
          playerNameList,
          itemToken,
          hadPreroll,
        }),
      );
    }
  };
}

export function stationFailed(failedWithHttp = false) {
  return (dispatch, getState) => {
    const {
      tunedGuideId: guideId,
      playerNameList,
      itemToken,
      tuneRequestStart,
      hadPreroll,
      hasAdBlocker,
    } = getState().player;

    const now = Date.now();

    if (tuneRequestStart) {
      const time = now - tuneRequestStart;

      dispatch(
        logTotalTuneFailTime({
          guideId,
          time,
          playerNameList,
          itemToken,
          hadPreroll,
          hasAdBlocker,
        }),
      );
    }

    return dispatch({
      type: FAILED,
      failedWithHttp,
    });
  };
}

export function qualifiedTune() {
  return (dispatch, getState) => {
    const state = getState();
    const { tunedGuideId: guideId, itemToken } = state.player;
    const boostGuideId = selectBoostGuideId(state);

    dispatch(
      logPlayStartQualified({ guideId: boostGuideId || guideId, itemToken }),
    );
    dispatch({
      type: QUALIFIED_TUNE,
    });
  };
}

export function handleHtmlStream(externalHtmlPlayerUrl) {
  return {
    type: HANDLE_HTML_STREAM,
    externalHtmlPlayerUrl,
  };
}

export function handleStreamEvaluation(time) {
  return {
    type: START_STREAM_EVALUATION,
    streamEvaluationStart: time || Date.now(),
  };
}

// called from the tuner
export function nextTune({ nextGuideId: guideId, itemToken }, history) {
  return (dispatch) => {
    const nextTuneAction = {
      type: NEXT_TUNE,
      itemToken,
    };

    dispatch(
      logPlayStartNext({
        guideId,
        itemToken,
      }),
    );

    // NOTE/TODO: currently restricting redirect and now playing link updates to profile guide ids
    // only. This is to avoid unnecessary regressions for topic tunes. If we come to a point where
    // we want to adjust and redirect for topic tunes (e.g. update profile url with topicid, and
    // then update nowplaying link with topicid), we should change this to handle all types of
    // next guide ids generically.
    if (isProfile(guideId)) {
      // Perform a fresh profiles API fetch to get/update profile data, as well as allow us to
      // form the profile url
      return dispatch(fetchProfile({ guideId, itemToken }))
        .catch((error) => {
          dispatch(nextTuneAction);

          throw error;
        })
        .then(({ action }) => {
          // NOTE: this is the direct output structure from apiClientEffects from a resolved
          // request.
          const guideItem = action.payload.content;
          const redirectPathname = buildGuideItemPathnameWithLogger(guideItem)(
            (...args) => dispatch(logClientError(...args)),
          );

          dispatch({
            ...nextTuneAction,
            guideItemPathname: redirectPathname,
          });

          if (redirectPathname) {
            // Because we already performed a profiles API fetch above, we don't want to
            // perform a duplicate one. We'll use the `doNotFetch` instruction to prevent async
            // data loading from occurring once the route changes to the new profile page.
            history.replace({
              pathname: redirectPathname,
              state: {
                [doNotFetch]: true,
              },
            });
          }
        });
    }

    return dispatch(nextTuneAction);
  };
}

export function handleMeta(data) {
  const IMG_SCALE = 300;
  return (dispatch, getState) => {
    const meta = {};
    let extraData = {};

    // Search first object for TXXX regardless of its version key name
    const keys = Object.keys(data?.native);
    const txxx =
      data?.native[keys[0]] &&
      data.native[keys[0]].find((tag) => tag?.id?.match(/^TXXX/));
    if (txxx) {
      // Clean up and split the space separated key/value string (e.g. 'song_spot="M" TPID="0"')
      extraData = txxx.value
        .replace(/"/g, '')
        .split(' ')
        .reduce((acc, item) => {
          const [key, value] = item.split('=');

          acc[key] = value;

          return acc;
        }, {});
    } else {
      // Cannot rely on artist without song_spot (i.e. could be garbage ad data)
      return;
    }

    // If song_spot is "M" (music) and artist is known
    if (data?.common?.artist && extraData?.song_spot === 'M') {
      meta.title = `${data.common.artist} - ${data.common.title}`;
      // If we are allowed to use album art (flagged just in case)
      const isAlbumArtEnabled = selectExperiment(
        getState(),
        IHEARTRADIO_ALBUM_ART_ENABLED,
      );
      if (isAlbumArtEnabled) {
        // If TPID is not zero, use iHeart image scaler
        if (Number.parseInt(extraData.TPID, 10) >= 0) {
          meta.image = `https://i.iheart.com/v3/catalog/track/${extraData.TPID}?ops=fit(${IMG_SCALE},${IMG_SCALE})`;
        }
      }
      // Stop the poller since we are getting info directly from ID3 metadata
      // The poller will restart on next tune.
      tunerSingleton.instance?.stopPoller();
      return dispatch({ type: HANDLE_META, meta });
    }
  };
}

export function setListenId(setToNull = false) {
  return {
    type: SET_LISTEN_ID,
    listenId: setToNull ? null : Date.now(),
  };
}

export function initiateIntro() {
  return {
    type: INITIATE_INTRO,
  };
}

export function initiateOutro() {
  return {
    type: INITIATE_OUTRO,
  };
}

export function setTunerReady() {
  return {
    type: TUNER_READY,
  };
}
