import {
  getTypeLowerCase,
  isProgram,
  isTopic,
  lotame,
} from '@tunein/web-common';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import localStorage from 'src/client/utils/localStorage';
import oneTrust from 'src/common/consent/OneTrust';
import { BRAZE_PLAY_EVENT } from 'src/common/constants/braze';
import { selectBreadcrumbId } from 'src/common/selectors/reporting';
import {
  fbPixelCustomEvents,
  fbTrack,
} from '../../client/analytics/facebook/fbTrack';
import { unifiedEvents } from '../../client/api';
import { mintSingleton } from '../../client/mint';
import { tunerSingleton } from '../../client/tuner';
import { PREROLL_SLOT_NAME } from '../components/ads/constants/displayAds';
import createAndOpenSubscribeLink from '../components/utils/createAndOpenSubscribeLink';
import { isAdBlockEnabledPromise } from '../components/utils/isAdBlockEnabled';
import feature from '../constants/analytics/categoryActionLabel/feature';
import { labels } from '../constants/analytics/categoryActionLabel/subscribe';
import { AUTOPLAY_GUIDE_ID } from '../constants/categories';
import { AUTOPLAY } from '../constants/containerTypes';
import { BYPASS_UPSELL_DIALOG_ENABLED } from '../constants/experiments/dialog';
import { PLAY_OPEN_IN_APP_ENABLED } from '../constants/experiments/redirect';
import { MAP_VIEW } from '../constants/paths';
import { PREROLL_PLAYED } from '../constants/player/preroll';
import { playerStatuses } from '../constants/playerStatuses';
import { player } from '../constants/reporting/sandboxCategoryActionProps';
import {
  selectBranchUrl,
  selectDiscordState,
  selectGdprApplies,
  selectGdprTcString,
  selectIsBrazeInitialized,
  selectIsDiscord,
  selectIsMobile,
  selectIsOptedOutOfTargeting,
  selectUsPrivacyString,
} from '../selectors/app';
import { selectAutoPlayCategory } from '../selectors/categories';
import { selectCountryCode, selectExperiment } from '../selectors/config';
import { selectIsUserSubscribed } from '../selectors/me';
import {
  selectAutoPlayGuideItem,
  selectBoostGuideId,
  selectHasAdBlocker,
  selectIsBoostFeaturedInPlayer,
  selectIsMediaAdLoaded,
  selectListenId,
  selectNowPlaying,
  selectNowPlayingAds,
  selectNowPlayingRejectReasonKey,
  selectPlayerStatus,
  selectTunedGuideId,
} from '../selectors/player';
import { getSelectProfile } from '../selectors/profiles';
import midrollScheduler from '../utils/ads/MidrollScheduler';
import { canShowPersonalizedAds } from '../utils/ads/canShowPersonalizedAds';
import { getAdsTargeting } from '../utils/ads/getAdsTargeting';
import { brazeLogCustomEvent } from '../utils/braze/brazeHelper';
import { generateUniqueId } from '../utils/generateUniqueId';
import getGuideItemPathname from '../utils/guideItem/getGuideItemPathname';
import getTunedGuideId from '../utils/guideItem/getTunedGuideId';
import isPlayable from '../utils/guideItem/isPlayable';
import { behaviors as GuideItemBehaviors } from '../utils/guideItemTypes';
import isUserInUSOrUSTerritories from '../utils/isUserInUSOrUSTerritories';
import { setVolume as setVolumeInStorage } from '../utils/storage/playerVolume';
import { fetchCategory } from './browse';
import { openUpsellAndTrackActivity } from './dialog';
import {
  logBoostOptInAction,
  logBoostOptOutAction,
  logClientError,
  logClientInfo,
  logFeatureActivity,
  logPlayStart,
  logPrerollEligible,
} from './logging';
import { setPlaybackHistory } from './playbackHistory';
import { fetchProfile } from './profile';
import * as TunerActions from './tuner';

const PRE_TUNE = 'PRE_TUNE';
const REQUEST_TUNE = 'REQUEST_TUNE';
const HANDLE_LOAD = 'HANDLE_LOAD';
const PLAY = 'PLAY';
const PAUSE = 'PAUSE';
const STOP = 'STOP';
const SEEK = 'SEEK';
const MUTE = 'MUTE';
const SET_VOLUME = 'SET_VOLUME';
const SET_TUNE_CONTEXT_LABEL = 'SET_TUNE_CONTEXT_LABEL';
const SET_HAS_ADBLOCKER = 'SET_HAS_ADBLOCKER';
const SET_SHOW_VOLUME_BAR = 'SET_SHOW_VOLUME_BAR';
const OPEN_POPOUT_PLAYER = 'OPEN_POPOUT_PLAYER';
const SET_POPOUT_PLAYER_STATUS = 'SET_POPOUT_PLAYER_STATUS';
const FETCH_NOW_PLAYING = 'FETCH_NOW_PLAYING';
const AUTOPLAY_READY = 'AUTOPLAY_READY';
const MEDIA_AD_LOADED = 'MEDIA_AD_LOADED';
const MEDIA_AD_PLAYING = 'MEDIA_AD_PLAYING';
const MEDIA_AD_ENDED = 'MEDIA_AD_ENDED';
const MEDIA_AD_ERROR = 'MEDIA_AD_ERROR';
const WAITING_FOR_GDPR = 'WAITING_FOR_GDPR';
const SET_GUIDE_ITEM_PATHNAME = 'SET_GUIDE_ITEM_PATHNAME';
// Boost Specific actions
const FEATURE_BOOST_IN_PLAYER = 'FEATURE_BOOST_IN_PLAYER';
const SET_BOOST_TOOLTIP = 'SET_BOOST_TOOLTIP';
const END_INTRO = 'END_INTRO';
const END_OUTRO = 'END_OUTRO';

function handleLoad() {
  return {
    type: HANDLE_LOAD,
  };
}

function handlePlay() {
  return {
    type: PLAY,
  };
}

function handlePause() {
  return {
    type: PAUSE,
  };
}

function handleStop() {
  return {
    type: STOP,
  };
}

function handleSeek(positionPercent, isDiscordSyncRequest = false) {
  return {
    type: SEEK,
    positionPercent,
    isDiscordSyncRequest,
  };
}

function handleSetVolume(value) {
  return {
    type: SET_VOLUME,
    value,
  };
}

function handleMute(value) {
  return {
    type: MUTE,
    value,
  };
}

function setTuneContextLabel(label) {
  return {
    type: SET_TUNE_CONTEXT_LABEL,
    label,
  };
}

// NOTE: this is really solving for the intermediary case where a topic ends and a next one is set
// to play.
function hasNowPlayingMetaData(nowPlaying) {
  return nowPlaying && !!nowPlaying.subtitle && !!nowPlaying.title;
}

function buildDfpUrlParams(guideId) {
  // Important: URL must not redirect, so we need the trailing slash or else our route controller
  // will add one and force a redirect. https://tunein.atlassian.net/browse/TUNE-12903
  const url = `https://tunein.com/desc/${guideId}/`;
  return {
    url,
    description_url: encodeURIComponent(url),
  };
}

// exported for testing
async function buildTuneParams(getState, dfpParams) {
  const state = getState();
  const lotameAudiences = lotame.getAudiences();
  const gdprTcString = selectGdprTcString(state);
  const isUserInUS = isUserInUSOrUSTerritories(selectCountryCode(state));
  const usPrivacyString = selectUsPrivacyString(state);
  const isOptedOutOfTargeting = selectIsOptedOutOfTargeting(state) || false;
  const gdprApplies = selectGdprApplies(state);
  const optedInGeneralVendorIds = gdprApplies
    ? await oneTrust.getOptedInGeneralVendorIds()
    : undefined;

  const params = {
    gdprApplies,
    isOptedOut: !canShowPersonalizedAds(state),
    isOptedOutOfCcpaOrGlobal: isOptedOutOfTargeting,
    isUserInUS,
    dfpParams,
    adsTargeting: getAdsTargeting(state),
  };

  if (usPrivacyString) {
    params.usPrivacyString = selectUsPrivacyString(state);
  }

  if (!selectIsUserSubscribed(state) && !selectHasAdBlocker(state)) {
    params.palNonce = await mintSingleton.instance?.getPalNonce();
  }

  if (gdprTcString) {
    params.gdprTcString = gdprTcString;
  }

  if (lotameAudiences.length) {
    params.audience = `;${lotameAudiences.join(';')};`;
  }

  if (optedInGeneralVendorIds) {
    params.genVendors = optedInGeneralVendorIds;
  }

  return params;
}

// NOTE: exporting because it's too cumbersome to test through the tune action.  This is ok, as the
// tune action does not have branch logic based on the branch logic here.
function requestTune(playerState, params = {}) {
  const {
    tunedGuideId,
    parentGuideId,
    nowPlaying,
    guideItemPathname,
    itemToken,
  } = params;

  return {
    type: REQUEST_TUNE,
    tunedGuideId: tunedGuideId || playerState.tunedGuideId,
    parentGuideId,
    nowPlaying: hasNowPlayingMetaData(nowPlaying)
      ? nowPlaying
      : playerState.nowPlaying,
    itemToken,
    guideItemPathname,
    tuneRequestStart: Date.now(),
  };
}

function seek(positionPercent, isDiscordSyncRequest) {
  return (dispatch, getState) => {
    const discordState = selectDiscordState(getState());

    if (!(discordState.canControlPlayback || isDiscordSyncRequest)) {
      return;
    }

    tunerSingleton.instance?.seek(positionPercent);

    return dispatch(handleSeek(positionPercent, isDiscordSyncRequest));
  };
}

function play({
  listeningReportData,
  isDiscordSyncRequest,
  wasTunedGuideIdInFailedState,
} = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const discordState = selectDiscordState(state);

    if (
      !(discordState.canControlPlayback || isDiscordSyncRequest) &&
      !wasTunedGuideIdInFailedState
    ) {
      return;
    }

    if (selectIsMediaAdLoaded(state)) {
      mintSingleton.instance?.play();
      return null;
    }

    tunerSingleton.instance?.play();

    if (listeningReportData) {
      unifiedEvents.reportListenSessionStarted(listeningReportData);
    }

    return dispatch(handlePlay());
  };
}

function pause(isDiscordSyncRequest) {
  return (dispatch, getState) => {
    const state = getState();
    const discordState = selectDiscordState(state);

    if (!(discordState.canControlPlayback || isDiscordSyncRequest)) {
      return;
    }

    if (selectIsMediaAdLoaded(state)) {
      mintSingleton.instance?.pause();
      return null;
    }

    tunerSingleton.instance?.pause();
    dispatch(
      setPlaybackHistory(
        state.player.tunedGuideId,
        state.player.positionInfo.elapsedPercent,
      ),
    );
    return dispatch(handlePause());
  };
}

function stop(isDiscordSyncRequest) {
  return (dispatch, getState) => {
    const state = getState();
    const discordState = selectDiscordState(state);

    if (!(discordState.canControlPlayback || isDiscordSyncRequest)) {
      return;
    }

    tunerSingleton.instance?.stop();
    return dispatch(handleStop());
  };
}

function setVolume(value) {
  return (dispatch) => {
    setVolumeInStorage(value);
    tunerSingleton.setVolume(value);
    return dispatch(handleSetVolume(value));
  };
}

function setShowVolumeBar(value) {
  return {
    type: SET_SHOW_VOLUME_BAR,
    value,
  };
}

function toggleMute() {
  return (dispatch, getState) => {
    const { player } = getState();

    // Mobile does not have a volume control, so we need to mute instead
    player.muted = player.muted === undefined ? true : !player.muted;
    tunerSingleton.instance?.toggleMute();

    return dispatch(handleMute(player.muted));
  };
}

function primeTuneForMobileSync() {
  return (dispatch) => {
    tunerSingleton.instance?.preTune();
    return dispatch({ type: PRE_TUNE });
  };
}

function featureBoostInPlayer(isBoostFeaturedInPlayer) {
  return {
    type: FEATURE_BOOST_IN_PLAYER,
    isBoostFeaturedInPlayer,
  };
}

function endIntro() {
  return {
    type: END_INTRO,
  };
}

function endOutro() {
  return {
    type: END_OUTRO,
  };
}

function setBoostTooltip(isBoostTooltipOpen) {
  return {
    type: SET_BOOST_TOOLTIP,
    isBoostTooltipOpen,
  };
}

function loadAndPlay({
  listeningReportData,
  isDiscordSyncRequest,
  wasTunedGuideIdInFailedState,
}) {
  return (dispatch, getState) => {
    const state = getState();
    const isBoostFeaturedInPlayer = selectIsBoostFeaturedInPlayer(state);

    dispatch(handleLoad());

    if (isBoostFeaturedInPlayer) {
      unifiedEvents.reportListenSessionStarted(listeningReportData);
      dispatch(handlePlay());
    } else {
      dispatch(TunerActions.handleStreamEvaluation());
      dispatch(
        play({
          listeningReportData,
          isDiscordSyncRequest,
          wasTunedGuideIdInFailedState,
        }),
      );
    }
  };
}

async function attemptPreroll(dispatch, state) {
  const isMobile = selectIsMobile(state);
  const { canShowAds, canShowPrerollAds, canShowVideoPrerollAds } =
    selectNowPlayingAds(state);
  const isDiscord = selectIsDiscord(state);
  const tunedGuideId = selectTunedGuideId(state);

  const isPrerollDisabledOnDiscord = isDiscord && isTopic(tunedGuideId);
  const canShowPreroll =
    canShowAds && (canShowPrerollAds || canShowVideoPrerollAds);

  const isPrerollEligible =
    !isMobile && !isPrerollDisabledOnDiscord && canShowPreroll;

  if (isPrerollEligible) {
    dispatch(
      logPrerollEligible({
        audioEnabled: canShowPrerollAds,
        videoEnabled: canShowVideoPrerollAds,
      }),
    );

    await mintSingleton.instance?.requestSlot(PREROLL_SLOT_NAME).catch(noop);
  } else {
    dispatch(logPrerollEligible({ audioEnabled: false, videoEnabled: false }));
    unifiedEvents.reportAdEligiblity({
      audio: canShowAds && canShowPrerollAds,
      video: canShowAds && canShowVideoPrerollAds,
    });
  }
}

function requestPreroll({ guideId, disablePreroll }) {
  return async (dispatch, getState) => {
    const state = getState();
    const isUserSubscribed = selectIsUserSubscribed(state);
    const autoPlayGuideItem = selectAutoPlayGuideItem(state);
    const { canShowAds, canShowPrerollAds, canShowVideoPrerollAds } =
      selectNowPlayingAds(state);
    const isAutoPlayingRecents = guideId === autoPlayGuideItem?.guideId;
    const isMapViewRoute = window.location.pathname === MAP_VIEW;

    if (
      !isUserSubscribed &&
      !isAutoPlayingRecents &&
      !disablePreroll &&
      !isMapViewRoute
    ) {
      midrollScheduler.reset();
      // Stop any currently playing media ad before attempting preroll
      mintSingleton.instance?.stop();
      await attemptPreroll(dispatch, getState());
    } else {
      dispatch(
        logPrerollEligible({
          audioEnabled: false,
          videoEnabled: false,
        }),
      );
      unifiedEvents.reportAdEligiblity({
        audio: canShowAds && canShowPrerollAds,
        video: canShowAds && canShowVideoPrerollAds,
      });
    }
  };
}

function buildListeningReportData({
  guideId,
  parentGuideId,
  breadcrumbId,
  listenId,
}) {
  return {
    guideId,
    parentGuideId: parentGuideId || '',
    listenId: listenId?.toString() || '',
    breadcrumbId,
  };
}

// Autoplay is enabled by default on app load, but if a user clicks play before autoplay logic kicks in (which depends on
// isGdprReady resolving to true), we'll want to disable autoplay, otherwise we may have an in-process tune() call that's
// interrupted by autoplay's tune() call, which can create a bizarre user experience like delayed time-to-tune or UI glitches.
// Note: we don't want to make this a Redux state because the delay in dispatching that flag could introduce a
// race condition.
let canAutoplay = true;
/**
 * Kicks off tune request and stream playback in web-tuner and
 * requests prerolls from mint, when applicable (via attemptPreroll()).
 *
 * @param {object} requestOptions
 * @returns {function(*, *=): Promise<undefined|null|*>}
 */
function tune({
  audioClipId,
  topicGuideId,
  guideItem = {},
  formats,
  isAutoplayed,
  playFromPosition,
  location,
  disablePreroll,
  ignoreIsPlayable,
}) {
  /* eslint-disable consistent-return */
  return async (dispatch, getState) => {
    if (isAutoplayed && !canAutoplay) {
      return;
    }

    canAutoplay = false;

    const state = getState();
    const isUserSubscribed = selectIsUserSubscribed(state);

    await mintSingleton.readyPromise;
    await tunerSingleton.readyPromise;

    const isMediaAdLoaded = selectIsMediaAdLoaded(state);
    const playerGuideId = selectTunedGuideId(state);
    const tunedGuideId = getTunedGuideId(guideItem, audioClipId, topicGuideId);
    const isBoostFeaturedInPlayer = selectIsBoostFeaturedInPlayer(state);
    const discordState = selectDiscordState(state);
    const playerStatus = selectPlayerStatus(state);
    const wasTunedGuideIdInFailedState =
      playerGuideId === tunedGuideId && playerStatus === playerStatuses.failed;
    let parentGuideId;

    if (!discordState.canControlPlayback && !wasTunedGuideIdInFailedState) {
      return;
    }

    // Reset preroll played value
    localStorage.set(PREROLL_PLAYED, 'false');

    if (
      !isUserSubscribed &&
      tunedGuideId === playerGuideId &&
      isMediaAdLoaded &&
      !disablePreroll
    ) {
      dispatch(
        logClientInfo({
          message: 'mint.play() called from tune()',
          context: {
            playerStatus,
          },
        }),
      );

      mintSingleton.instance?.play();
      return null;
    }

    if (isMediaAdLoaded && playerStatus !== playerStatuses.paused) {
      mintSingleton.instance?.stop();
    }

    // regular upsell triggered by attempt to play a locked item
    // this instance should only be triggered on the large profile play button
    // because the lock tile covers other cases
    // - blocks playback
    const behaviorAction = guideItem.behaviors?.primaryButton?.actionName;
    const SOURCE = 'tune.action';
    if (behaviorAction === GuideItemBehaviors.subscribe) {
      if (selectExperiment(state, BYPASS_UPSELL_DIALOG_ENABLED, false)) {
        createAndOpenSubscribeLink(guideItem, location, null, SOURCE);
        return null;
      }
      return dispatch(
        openUpsellAndTrackActivity(labels.profileUpsellDialog, tunedGuideId),
      );
    }

    if (!isPlayable(guideItem) && !ignoreIsPlayable) {
      return null;
    }

    // on attempt to play, open via branch deep link
    const isMobile = selectIsMobile(state);
    const branchUrl = selectBranchUrl(state);
    const breadcrumbId = selectBreadcrumbId(state);
    const playOpenInAppStore = selectExperiment(
      state,
      PLAY_OPEN_IN_APP_ENABLED,
    );

    if (isMobile && playOpenInAppStore && branchUrl) {
      window.location.replace(branchUrl);
      return null;
    }

    // NOTE: Boost playback is initiated via playBoostStation & retuneDirectoryStation
    // So, we can reset intro/outro inProgress state anytime we go through tune()
    dispatch(endIntro());
    dispatch(endOutro());

    const itemToken = guideItem.context?.token;
    const isTopicGuideId = isTopic(tunedGuideId);
    const params = {
      tunedGuideId,
      nowPlaying: {
        title: isTopicGuideId ? guideItem.title : guideItem.subtitle,
        subtitle: isTopicGuideId ? guideItem.subtitle : guideItem.title,
        image: guideItem.image,
        share: guideItem.actions?.share,
        description: guideItem.description,
        slogan: guideItem.metadata?.properties?.station?.slogan,
      },
      itemToken,
    };

    if (isTopicGuideId) {
      // The accompanying guide item for a topic guide ID can be either a program guide item or
      // a topic guide item. These guide items have different models for the `properties` attribute,
      // so we're treating the guide item as a topic first, then falling back to the program model.
      parentGuideId =
        guideItem.properties?.parentProgram?.guideId ||
        guideItem.properties?.seoInfo?.guideId;
      params.parentGuideId = parentGuideId;

      // in order to display correct favorite options in the tuner as well as favorite state
      // we need to fetch the parent profile for topics in cases when a topic is tuned to
      // without loading the profile page (i.e. search)
      if (parentGuideId && !getSelectProfile(parentGuideId)(state)) {
        dispatch(fetchProfile({ guideId: parentGuideId }));
      }

      const parentGuideItem = state.profiles?.[parentGuideId];
      params.guideItemPathname = getGuideItemPathname(parentGuideItem);
    } else {
      params.guideItemPathname = getGuideItemPathname(guideItem);
    }

    dispatch(TunerActions.setListenId());

    const listeningReportData = buildListeningReportData({
      guideId: tunedGuideId,
      parentGuideId,
      breadcrumbId,
      listenId: selectListenId(getState()),
    });

    fbTrack(fbPixelCustomEvents.StartPlay, {
      guideId: tunedGuideId,
      isAutoplayed,
    });
    unifiedEvents.reportUserPlayClicked(listeningReportData);
    dispatch(logPlayStart({ isAutoplayed, guideId: tunedGuideId, itemToken }));
    dispatch(primeTuneForMobileSync());
    dispatch(requestTune(state.player, params));

    mintSingleton.updateState('tuneRequested', true);

    // https://tunein.atlassian.net/browse/TUNE-12903
    const dfpUrlParams = buildDfpUrlParams(parentGuideId || tunedGuideId);
    mintSingleton.updateState('dfpParams', dfpUrlParams);

    const isAutoPlayingRecents = guideItem === selectAutoPlayGuideItem(state);

    if (isAutoPlayingRecents) {
      const { actions, labels: featureLabel } = feature;
      dispatch(
        logFeatureActivity(actions.autoPlay, featureLabel.playMiniPlayer),
      );
    }

    // Calling preload as early as possible to load the IMA SDK and to get ahead of mobile browsers potentially blocking
    // ad playback for lack of user interaction.
    if (
      !isUserSubscribed &&
      !isAutoPlayingRecents &&
      !disablePreroll &&
      !selectHasAdBlocker(state)
    ) {
      await mintSingleton.instance?.preload();
    }

    const loadOptions = {
      formats,
      guideId: tunedGuideId,
      itemToken,
      params: await buildTuneParams(getState, dfpUrlParams),
      title: guideItem.title,
      type: getTypeLowerCase(tunedGuideId),
      playFromPosition,
      isUserSubscribed,
    };

    try {
      mintSingleton.updateState('playSessionId', generateUniqueId());
      const loadMeta = await tunerSingleton.instance?.load(loadOptions);

      if (
        !isUserSubscribed &&
        !disablePreroll &&
        (await isAdBlockEnabledPromise.promise)
      ) {
        dispatch(TunerActions.stationFailed());
        return;
      }

      if (isBoostFeaturedInPlayer) {
        await dispatch(featureBoostInPlayer(false));
      }

      if (loadMeta?.isHtmlStream) {
        return;
      }

      if (loadMeta?.rejectReason) {
        unifiedEvents.reportListenSessionStarted(listeningReportData);
        unifiedEvents.reportSandbox({
          category: player.category,
          action: player.actions.reject,
          props: { errorType: loadMeta.rejectReason },
        });
        tunerSingleton.instance?.stop();
        return dispatch(handleStop());
      }

      await dispatch(
        requestPreroll({
          guideId: guideItem.guideId,
          disablePreroll,
        }),
      );

      const isBrazeInitialized = selectIsBrazeInitialized(state);

      if (isBrazeInitialized) {
        try {
          const nowPlaying = selectNowPlaying(getState());

          // By sending this custom event to Braze triggers to show the In-App Message. Note: Make sure it happens after the preroll has finished playing.
          brazeLogCustomEvent(BRAZE_PLAY_EVENT, {
            guideId: tunedGuideId,
            boostEnabledStation: !isEmpty(nowPlaying?.boost),
            title: nowPlaying?.header?.title || nowPlaying?.title,
            ...nowPlaying?.classification,
            ...(isProgram(tunedGuideId) && {
              topicId: nowPlaying?.secondaryGuideId,
            }),
          });
        } catch (err) {
          dispatch(
            logClientError({
              message: `Braze Custom Event: ${BRAZE_PLAY_EVENT} Error`,
              context: {
                error: err,
              },
            }),
          );
          // NOTE: Don't break app functionality for Braze
        }
      }

      // NOTE: Need to run selectIsBoostFeaturedInPlayer with a fresh pull from state,
      // as if a user initiates a switch during tune() preroll, and then decides to 'boost'
      // playBoostStation() will run and update isBoostFeaturedInPlayer in redux before it then stops mint (preroll playback)
      // upon mint.stop, requestPreroll above will finish and if isBoostFeaturedInPlayer, the app will eject from the tune
      // and playBoostStation() will move forward with playback for the boost experience
      if (selectIsBoostFeaturedInPlayer(getState())) {
        return;
      }

      dispatch(
        loadAndPlay({ listeningReportData, wasTunedGuideIdInFailedState }),
      );
    } catch (e) {
      return dispatch(TunerActions.stationFailed());
    }
  };
  /* eslint-enable consistent-return */
}

function tuneWithGuideId({
  guideId,
  parentGuideId,
  itemToken,
  guideItemPathname,
  primeTune,
  disablePreroll,
  shouldEndBoost,
  boostActionLabel,
  playFromPosition,
  isDiscordSyncRequest,
} = {}) {
  return async (dispatch, getState) => {
    if (!guideId) {
      return;
    }

    await mintSingleton.readyPromise;
    await tunerSingleton.readyPromise;

    const state = getState();
    const isUserSubscribed = selectIsUserSubscribed(state);
    const autoPlayGuideItem = selectAutoPlayGuideItem(state);
    const listenId = selectListenId(state);
    const isBoostFeaturedInPlayer = selectIsBoostFeaturedInPlayer(state);
    const breadcrumbId = selectBreadcrumbId(state);
    const discordState = selectDiscordState(state);
    const playerStatus = selectPlayerStatus(state);
    const tunedGuideId = selectTunedGuideId(state);
    const wasTunedGuideIdInFailedState =
      guideId === tunedGuideId && playerStatus === playerStatuses.failed;
    const isAutoPlayingRecents = guideId === autoPlayGuideItem?.guideId;

    if (
      !(discordState.canControlPlayback || isDiscordSyncRequest) &&
      !wasTunedGuideIdInFailedState
    ) {
      return;
    }

    await dispatch(TunerActions.setListenId());

    let listeningReportData = buildListeningReportData({
      guideId,
      parentGuideId,
      breadcrumbId,
      listenId: selectListenId(getState()),
    });

    if (!isDiscordSyncRequest) {
      unifiedEvents.reportUserPlayClicked(listeningReportData);
    }

    dispatch(logPlayStart({ guideId, itemToken }));

    dispatch(
      requestTune(state.player, {
        tunedGuideId: guideId,
        guideItemPathname,
        itemToken,
      }),
    );

    if (boostActionLabel) {
      const newListenId = selectListenId(getState());

      listeningReportData = buildListeningReportData({
        guideId,
        parentGuideId,
        breadcrumbId,
        listenId: newListenId,
      });

      if (isBoostFeaturedInPlayer) {
        // User entering boost experience
        dispatch(
          logBoostOptInAction(`${boostActionLabel}.${listenId}.${newListenId}`),
        );
      }

      if (shouldEndBoost) {
        // User leaving boost experience
        dispatch(
          logBoostOptOutAction(
            `${boostActionLabel}.${listenId}.${newListenId}`,
          ),
        );
      }
    } else {
      // boostActionLabel handles all switch events except for OptIn via Tooltip
      // isBoostFeaturedInPlayer covers the toolTip optIn switch playback
      // and for all other playback flows, app should update playSessionId in Mint
      if (!isBoostFeaturedInPlayer) {
        mintSingleton.updateState('playSessionId', generateUniqueId());
      }
    }

    if (primeTune) {
      dispatch(primeTuneForMobileSync());
    }

    mintSingleton.updateState('tuneRequested', true);
    // https://tunein.atlassian.net/browse/TUNE-12903
    const dfpUrlParams = buildDfpUrlParams(parentGuideId || guideId);
    mintSingleton.updateState('dfpParams', dfpUrlParams);

    // Calling preload as early as possible to load the IMA SDK and to get ahead of mobile browsers potentially blocking
    // ad playback for lack of user interaction.
    if (
      !isUserSubscribed &&
      !isAutoPlayingRecents &&
      !disablePreroll &&
      !selectHasAdBlocker(state)
    ) {
      await mintSingleton.instance?.preload();
    }

    try {
      const loadMeta = await tunerSingleton.instance?.load({
        guideId,
        params: await buildTuneParams(getState, dfpUrlParams),
        type: getTypeLowerCase(guideId),
        itemToken,
        playFromPosition,
      });

      if (
        !isUserSubscribed &&
        !disablePreroll &&
        (await isAdBlockEnabledPromise.promise)
      ) {
        dispatch(TunerActions.stationFailed());
        return;
      }

      if (loadMeta?.rejectReason) {
        unifiedEvents.reportListenSessionStarted(listeningReportData);
        unifiedEvents.reportSandbox({
          category: player.category,
          action: player.actions.reject,
          props: { errorType: loadMeta.rejectReason },
        });
        tunerSingleton.instance?.stop();
        return dispatch(handleStop());
      }

      if (!isBoostFeaturedInPlayer) {
        await dispatch(
          requestPreroll({
            guideId,
            disablePreroll,
          }),
        );
      }

      await dispatch(
        loadAndPlay({
          listeningReportData,
          isDiscordSyncRequest,
          wasTunedGuideIdInFailedState,
        }),
      );
    } catch (e) {
      dispatch(TunerActions.stationFailed());
    }
  };
}

function retune(retuneConfig = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const playerState = state.player;
    const {
      tunedGuideId,
      parentGuideId,
      itemToken,
      guideItemPathname,
      boostGuideId,
    } = playerState;
    const nowPlayingRejectReason = selectNowPlayingRejectReasonKey(state);

    if (!tunedGuideId || nowPlayingRejectReason) {
      return null;
    }

    return dispatch(
      tuneWithGuideId({
        guideId: boostGuideId || tunedGuideId,
        parentGuideId,
        itemToken,
        guideItemPathname,
        ...retuneConfig,
      }),
    );
  };
}
function setHasAdBlocker(hasAdBlocker) {
  return (dispatch) => dispatch({ type: SET_HAS_ADBLOCKER, hasAdBlocker });
}

function openPopOutPlayer() {
  return { type: OPEN_POPOUT_PLAYER };
}

function setPopoutPlayerStatus() {
  return { type: SET_POPOUT_PLAYER_STATUS };
}

function fetchNowPlaying(guideId) {
  return {
    type: FETCH_NOW_PLAYING,
    api: {
      endpoint: ['profile', 'nowPlaying'],
      args: [guideId],
    },
  };
}

function primePlayerForAutoPlay() {
  return async (dispatch, getState) => {
    await dispatch(fetchCategory({ guideId: AUTOPLAY_GUIDE_ID }));
    const autoPlayContainerItems = selectAutoPlayCategory(
      getState(),
    )?.containerItems;
    const autoPlayContainer =
      find(autoPlayContainerItems, (item) => item.containerType === AUTOPLAY)
        ?.children || [];
    const lastThreeItems = autoPlayContainer.slice(0, 3);
    const latestPlayableItem = find(lastThreeItems, (item) => isPlayable(item));

    if (latestPlayableItem) {
      await dispatch(fetchNowPlaying(latestPlayableItem.guideId));
      return dispatch({
        type: AUTOPLAY_READY,
        meta: {
          guideItem: latestPlayableItem,
          guideItemPathname: getGuideItemPathname(latestPlayableItem),
        },
      });
    }
  };
}

// NOTE: playBoostStation is the entrypoint into the boost experience
function playBoostStation(boostActionLabel) {
  return async (dispatch, getState) => {
    await dispatch(featureBoostInPlayer(true));
    mintSingleton.instance?.stop(); // stop any IMA playback (midroll / preroll)
    dispatch(endOutro()); // Set isOutroInProgress to false

    // need to dispatch featureBoostInPlayer() above first
    const boostGuideId = selectBoostGuideId(getState());

    try {
      dispatch(
        retune({
          disablePreroll: true,
          boostActionLabel,
          guideId: boostGuideId,
        }),
      );
    } catch (e) {
      // Player renders an error state
      return dispatch(TunerActions.stationFailed());
    }
  };
}

// NOTE: Use retuneDirectoryStation only when you are switching from boost station -> dir stations
function retuneDirectoryStation(boostActionLabel) {
  return async (dispatch) => {
    mintSingleton.instance?.stop();
    dispatch(endIntro());

    try {
      await dispatch(featureBoostInPlayer(false));
      dispatch(retune({ shouldEndBoost: true, boostActionLabel }));
    } catch (e) {
      // Player renders an error state
      return dispatch(TunerActions.stationFailed());
    }
  };
}

function setGuideItemPathname(guideItemPathname) {
  return {
    type: SET_GUIDE_ITEM_PATHNAME,
    guideItemPathname,
  };
}

function mediaAdLoaded(slotName) {
  return {
    type: MEDIA_AD_LOADED,
    slotName,
  };
}

function mediaAdPlaying() {
  return {
    type: MEDIA_AD_PLAYING,
  };
}

function mediaAdEnded() {
  localStorage.set(PREROLL_PLAYED, 'true');
  return { type: MEDIA_AD_ENDED };
}

function mediaAdError() {
  return { type: MEDIA_AD_ERROR };
}

export {
  // action types
  PRE_TUNE,
  REQUEST_TUNE,
  HANDLE_LOAD,
  PLAY,
  PAUSE,
  STOP,
  SEEK,
  SET_VOLUME,
  SET_TUNE_CONTEXT_LABEL,
  SET_HAS_ADBLOCKER,
  SET_SHOW_VOLUME_BAR,
  OPEN_POPOUT_PLAYER,
  SET_POPOUT_PLAYER_STATUS,
  FETCH_NOW_PLAYING,
  AUTOPLAY_READY,
  MEDIA_AD_LOADED,
  MEDIA_AD_PLAYING,
  MEDIA_AD_ENDED,
  MEDIA_AD_ERROR,
  WAITING_FOR_GDPR,
  SET_GUIDE_ITEM_PATHNAME,
  // boost actions
  FEATURE_BOOST_IN_PLAYER,
  SET_BOOST_TOOLTIP,
  END_INTRO,
  END_OUTRO,
  // methods
  handlePlay,
  handlePause,
  buildDfpUrlParams,
  buildTuneParams,
  requestTune,
  seek,
  play,
  playBoostStation,
  pause,
  stop,
  setVolume,
  setShowVolumeBar,
  toggleMute,
  primeTuneForMobileSync,
  featureBoostInPlayer,
  endIntro,
  endOutro,
  setBoostTooltip,
  loadAndPlay,
  attemptPreroll,
  tune,
  retune,
  retuneDirectoryStation,
  setHasAdBlocker,
  openPopOutPlayer,
  setPopoutPlayerStatus,
  fetchNowPlaying,
  primePlayerForAutoPlay,
  mediaAdLoaded,
  mediaAdPlaying,
  mediaAdEnded,
  mediaAdError,
  handleSetVolume,
  tuneWithGuideId,
  setTuneContextLabel,
  setGuideItemPathname,
};
